<template>
  <div class="app-container">
    <div class="outer">
      <search-panel
        flex
        show-btn
        @getList="searchList"
      >
        <div>
          <p>发布内容</p>
          <el-input
            v-model="content"
            size="mini"
            style="width:240px;"
            clearable
            placeholder="输入发布内容关键字搜索"
          />
        </div>
        <div>
          <p>发布状态</p>
          <el-select
            v-model="stateSelect"
            placeholder="请选择发布状态"
            size="mini"
            style="width:240px;"
            clearable
          >
            <el-option
              v-for="item in stateSelectArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <p>发布起始时间</p>
          <el-date-picker
            v-model="tagtime"
            size="mini"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
        <el-button
          slot="button-right"
          style="margin-left:20px;"
          size="mini"
          @click="add"
        >
          发布动态
        </el-button>
      </search-panel>
    </div>
    <div
      class="outer"
      style="margin-top:20px;"
    >
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="拼命加载中"
        style="width: 100%"
        :header-row-style="{background: '#d9dde1'}"
        :header-cell-style="{background: '#d9dde1', color: '#0D0202', fontSize: '12px'}"
        @sort-change="changeSort"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="90"
        />

        <el-table-column
          prop="userName"
          label="发布者"
          width="90"
        />
        <el-table-column
          prop="goods_name"
          label="关联商品名称"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.tradeGoodsDto ?scope.row.tradeGoodsDto.name:'' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="发布内容"
          width="150"
        >
          <template slot-scope="scope">
            <span v-html="wordLimit(scope.row.content, 15)" />
            <el-popover
              v-if="scope.row.content.length > 15"
              placement="top-start"
              trigger="hover"
            >
              <div
                class="popover-box"
                v-html="scope.row.content"
              />
              <span
                slot="reference"
                class="detail-popover"
              >详情</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="topicName"
          label="参与话题名称"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.tradeTopicDtonide ?scope.row.tradeTopicDto.topicName:'' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="说说类型"
          width="100"
        >
          <template slot-scope="scope">
            {{ ['好物','上新'][Number(scope.row.talkType) - 1] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="readNum"
          label="浏览量"
        />
        <el-table-column
          prop="likeNum"
          label="点赞量"
        />
        <el-table-column
          label="发布状态"
          width="100"
        >
          <template slot-scope="scope">
            {{ ['隐藏','显示'][Number(scope.row.pubStatus)] }}
          </template>
        </el-table-column>
        <el-table-column label="发布时间">
          <template slot-scope="scope">
            <div>
              {{ setTime(scope.row.pubTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="250"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.pubStatus"
              size="mini"
              type="success"
              @click="operation('show', scope.row)"
            >
              显示
            </el-button>
            <el-button
              v-if="scope.row.pubStatus"
              size="mini"
              type="warning"
              @click="operation('hide', scope.row)"
            >
              隐藏
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="operation('edit', scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deleteLabel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--轮播图-->
    <div
      v-show="showCover"
      class="cover-box"
      @click.self="showCover=false"
    >
      <div
        v-show="imgList.length"
        class="my-swiper"
        style="overflow: hidden;"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item,index) in imgList"
            :key="index"
            class="swiper-slide"
          >
            <img
              :src="item"
              width="100%"
            >
          </div>
        </div>
        <div
          class="swiper-button-next"
          tabindex="0"
          role="button"
          aria-label="Next slide"
          aria-disabled="false"
        />
        <div
          class="swiper-button-prev swiper-button-disabled"
          tabindex="0"
          role="button"
          aria-label="Previous slide"
          aria-disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmBox from '@/components/common/confirm'
import { SHOP_NOW_STATE, SHOP_RECOMMEND_STATE } from '../config'
import { strLimit } from '@/assets/js/common'
import dayjs from 'dayjs'

export default {
  name: 'Agricultural',
  data() {
    return {
      is_read: 1, // 权限
      content: null, // 发布内容
      stateSelect: null, // 发布状态
      stateSelectArr: SHOP_NOW_STATE, // 发布状态下拉内容
      recommendSelect: null, // 推荐状态
      recommendSelectArr: SHOP_RECOMMEND_STATE, // 推荐状态下拉内容
      tableData: [], // 列表内容
      showCover: false, // 图片列表弹窗展示
      imgList: [], // 图片列表
      sort: 'ctime', // 排序类型
      sortType: 'desc', // 正序，倒序
      loading: false,
      createStartTime: null,
      createEndTime: null,
      tagtime: [],
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  watch: {
    tagtime(item) {
      if (item) {
        this.createStartTime = item[0]
        this.createEndTime = item[1]
      } else {
        this.createStartTime = null
        this.createEndTime = null
      }
    }
  },
  mounted() {
    this.is_read = parseInt(sessionStorage.getItem('is_read'), 10)
    this.getList(1)
  },
  methods: {
    searchList() {
      this.pagination.currentPage = 1
      this.getList()
    },
    add() {
      this.$router.push({
        path: '/nb/marketingTools/nombo/detail',
        query: { type: 'add' }
      })
    },

    // 显示图片列表
    showImg(row) {
      this.showCover = true
      this.imgList = row.imageUrlList
      const swiper = new Swiper('.my-swiper', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        observer: true
      })
    },
    operation(type, row) {
      if (type === 'edit') {
        this.$router.push({ path: '/nb/marketingTools/nombo/detail', query: { type: type, id: row.talkId } })
      } else if (type === 'hide' || type === 'show') {
        this.$confirm('确定修改该数据的状态吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(type, 'type')

          this.$axios({
            method: 'get',
            url: this.$api.farmer.pubstatus,
            params: {
              talkId: row.talkId,
              pubStatus: type === 'hide' ? 0 : 1
            }
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功')
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
      }
    },

    // 排序
    changeSort(row) {
      if (row.prop === 'show_time_first') {
        this.sort = 'ctime'
      } else if (row.prop === 'sort') {
        this.sort = 'sort'
      }
      if (row.order === 'ascending') {
        this.sortType = 'asc'
      } else if (row.order === 'descending') {
        this.sortType = 'desc'
      } else {
        this.sortType = 'desc'
      }
      this.getList()
    },

    // 置顶排序弹窗
    changeNum(row) {
      this.$prompt('确认要修改置顶排序吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '输入值只能是数字',
        inputValidator(value) {
          let reg = /^\+?[0-9]\d{0,7}(\.\d*)?$/
          if (!reg.test(Number(value))) {
            return '只能输入0~99999999之间的数字'
          }
        }
      }).then(res => {
        row.sort = res.value
        this.$axios({
          method: 'get',
          url: this.$api.farmer.topSort,
          params: {
            talkId: row.talkId,
            sort: res.value
          }
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功')
            this.getList()
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },

    // 删除
    deleteLabel(row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$axios({
          method: 'get',
          url: this.$api.farmer.delete,
          params: {
            talkId: row.talkId
          }
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功')
            this.getList()
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },

    // 更新信息
    updateInfo(row, num) {
      const data = {
        id: row.id
      }
      if (num === 0) {
        data.is_show = row.is_show ? 1 : 0
      } else if (num === 1) {
        data.is_recommend = row.is_recommend ? 1 : 0
      } else if (num === 2) {
        data.sort = row.sort
      } else if (num === 3) {
        data.status = 1
      }
      this.$axios.post(this.$api.getShopAgriculturalStatus, {
        ...data
      }).then(res => {
        const r = res || {}
        if (r.error === 0) {
          this.getList(this.pagination.page)
          this.$message('提交成功')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    // 获取列表数据
    getList(index) {
      const _this = this
      _this.loading = true
      _this.$axios.post(_this.$api.farmer.list, {
        currentPage: this.pagination.currentPage,
        pageSize: this.pagination.nowPageSize,
        beginPubTime: this.createStartTime ? this.createStartTime : null,
        endPubTime: this.createEndTime ? this.createEndTime : null,
        contentKeyWords: _this.content,
        pubStatus: _this.stateSelect,
        isRecommend: _this.recommendSelect
      }).then(res => {
        const r = res || {}
        _this.loading = false
        if (r.code === 0 &&　r.data) {
          const list = _.get(res, 'data.records', [])
          _this.tableData = list
          _this.pagination.count = Number(res.data.total)
          _this.tableData.forEach((item, index) => {
            item.imageUrlList = item.imageIds ? item.imageIds.split(',') : []
            item.is_show === 1
              ? _this.$set(item, 'is_show', true)
              : _this.$set(item, 'is_show', false)

            item.is_recommend === 1
              ? _this.$set(item, 'is_recommend', true)
              : _this.$set(item, 'is_recommend', false)
          })
        } else {
          _this.tableData = []
        }
      }).catch(error => {
        console.log(error)
      })
    },

    wordLimit(str, index) {
      return strLimit(str, index)
    },
    setTime(time) {
      return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .publish-img{
    width: 150px;
    height: 100px;
    margin-top: 3px;
    cursor: pointer;
    margin-right: 10px;
  }

  .cover-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    padding: 10px 35% 0;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
  }

  .popover-box{
    width: 500px;
    min-height: 30px;
    max-height: 400px;
    overflow-y: scroll;
    img{
        width: 100%;
        height: auto;
    }
  }

  .detail-popover{
    color: #50bfff;
    cursor: pointer;
  }
</style>
